import firebaseGoogle from "./firebase";
// import { FirebaseContext } from "./context";

// export { FirebaseContext };
export default firebaseGoogle;

// import firebase from "./firebase";
// import FirebaseContext from "./context";

// export { FirebaseContext };
// export default firebase;
