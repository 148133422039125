const firebaseConfig = {
  apiKey: "AIzaSyD-RsrNZfstiD4Vd9bv7NKJC2FT0Jkrp4Y",
  authDomain: "firestarter-e7fe7.firebaseapp.com",
  databaseURL: "https://firestarter-e7fe7.firebaseio.com",
  projectId: "firestarter-e7fe7",
  storageBucket: "firestarter-e7fe7.appspot.com",
  messagingSenderId: "68509341700",
  appId: "1:68509341700:web:a3032fdf35ebe8e710fd51",
};

export default firebaseConfig;
